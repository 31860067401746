// Styles SCSS
import "../sass/home.scss";

// Carousel
import "slick-carousel";

// pushbar
import Pushbar from "./utils/pushbar";

import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
//import 'simplebar/dist/simplebar.css';

//lazy load
import LazyLoad from "vanilla-lazyload";

$(document).ready(() => {
	new LazyLoad({
		elements_selector: ".lazy",
	});

	$(".header__search").on("click", function () {
		$(".searchform__input").slideToggle();
		$(".searchform__input").focus();
	});

	$(".partenaires-top").on("click", function () {
		$(".partenaires-links").slideToggle();
		$(".partenaires-links").focus();
	});

	// jQuery('.translate option:contains("Select Language")').text('langues');
	// jQuery('.translate option:contains("English")').text('EN');
	// jQuery('.translate option:contains("French")').text('FR');
	// jQuery('.translate option:contains("Spanish")').text('ES');
	// jQuery('.translate option:contains("FR")').attr('selected', true);

	$(".rollover:not(.actif)").mouseenter(function () {
		var newsrc = $(this)
			.find("img,input[type=image]")
			.attr("src")
			.replace("_off", "_on");
		$(this).find("img,input[type=image]").attr("src", newsrc);
	});
	$(".rollover:not(.actif)").mouseleave(function () {
		var newsrc = $(this)
			.find("img,input[type=image]")
			.attr("src")
			.replace("_on", "_off");
		$(this).find("img,input[type=image]").attr("src", newsrc);
	});

	$(".slider-bandeau").slick({
		infinite: true,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 2000,
		speed: 1000,
	});

	$(".agenda__container").slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: false,
		arrows: true,
		prevArrow: $(".prev-agenda"),
		nextArrow: $(".next-agenda"),
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					autoplay: false,
					arrows: true,
					prevArrow: $(".prev-agenda"),
					nextArrow: $(".next-agenda"),
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	var $status = $(".actualites__infos");
	var $actualitesSlider = $(".actualites__slider");

	$actualitesSlider.on(
		"init reInit afterChange",
		function (event, slick, currentSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			if (i < 10) {
				i = "0" + i;
			}
			$status.html(
				'<span class="count-left">' +
					i +
					"</span>" +
					'<span class="count-right">' +
					"/" +
					slick.slideCount +
					"</span>"
			);
		}
	);
	$actualitesSlider.slick({
		//dots: true,
		infinite: true,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 2000,
		prevArrow: $(".prev-actualites"),
		nextArrow: $(".next-actualites"),
	});

	jQuery("footer .location span").on("click", function () {
		jQuery("footer .location span").removeClass("active");
		jQuery(this).toggleClass("active");

		if (jQuery(this).hasClass("paris") && jQuery(this).hasClass("active")) {
			jQuery("footer img.france").removeClass("active");
			jQuery("footer img.paris").addClass("active");
		} else {
			jQuery("footer img.france").addClass("active");
			jQuery("footer img.paris").removeClass("active");
		}
	});

	new Pushbar({
		blur: true,
		overlay: true,
	});

	var Largeur = $(window).width();

	if (Largeur < 900) {
		$(".logo-pv").appendTo(".adresse");
	}
});
